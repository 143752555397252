<template>
  <div>
    <SvgLine ref="svgline" @map-finished="handleMapLoadFinished" @on-click-station="handleClickStation"/>

    <SvgStation ref="svgStation" :stationSvgModal.sync="stationSvgModal" :stationId="chooseStationId" :svgShowType="1"
        :assetIds="searchCondition.assetIds" :searchSchedules="searchSchedule" showStationPortray
        @svg-finished="svgFinished"/>
  </div>
</template>

<script>
import SvgLine from '@/components/svg/SvgLine'
import SvgStation from '@/components/svg/SvgStation'

import { ParseDate } from '@/utils/dateFormat'

export default {
  components: {
    SvgLine, SvgStation
  },
  data () {
    return {
      mapFinished: false,
      stationSvgModal: false, // 弹窗显示SVG站点地图
      chooseStationId: 0
    }
  },
  methods: {
    handleMapLoadFinished (val) {
      this.mapFinished = val
    },
    LoadMapData () {
      if (this.mapFinished) { // 验证地图是否加载完毕， 否则延迟1秒再次请求
        if (this.mapType === 'flicker') {
          this.$refs.svgline.loadFlickerStations(this.stations)
        } else {
          this.$refs.svgline.loadResourceStations(this.stations)
        }
      } else {
        setTimeout(() => {
          this.LoadMapData()
        }, 1000)
      }
    },
    handleClickStation (stationId) { // 地图点击站点事件
      this.chooseStationId = stationId
      this.stationSvgModal = true
    },
    hideMapOtherAsset (assetIds) {
      if (this.mapFinished) {
        this.$refs.svgline.hideOtherAsset(assetIds)
      } else {
        // 验证地图是否加载完毕， 否则延迟1秒再次请求
        setTimeout(() => {
          this.hideMapOtherAsset(assetIds)
        }, 1000)
      }
    },
    svgFinished () { // SVG站点地图加载完毕
      this.$refs.svgStation.initShowBrand()
      this.$refs.svgStation.choseSpinShow()
    }
  },
  computed: {
    beginUpdate () {
      return this.$store.state.stockFormat.beginUpdate
    },
    stations () {
      return this.$store.state.stockFormat.stations
    },
    mapType () {
      return this.$store.state.stockFormat.mapType
    },
    searchCondition () {
      return this.$store.state.stockFormat.searchCondition
    },
    mapShowAssets () {
      return this.$store.state.stockFormat.showAssets
    },
    searchSchedule () {
      return [{ startDate: '2024-01-01', endDate: ParseDate(new Date()) }]
    }
  },
  watch: {
    beginUpdate (val) {
      this.LoadMapData()
    },
    mapShowAssets (val) {
      this.hideMapOtherAsset(val)
    }
  }
}
</script>
